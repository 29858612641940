import { useState } from "react";
import { FiltrosMetricas } from "../FiltrosComponents";
import { formatDate } from "../../utilities/formatDate";
import { FilterCitas, ShowFilters } from "../../types";
import { MetricsContactabilidadLayout } from "./MetricsContactabilidad";
import { MetricsCitasIngresadasLayout } from "./MetricsCItasIngresadas";


export function MetricasLayout() {
    const showFilters: ShowFilters = {
        fechas: true,
        sucursales: true,
        canales: false,
        respuestas: false,
        doctores: false,
    };

    const [filters, setFilters] = useState<FilterCitas>({
        fechaInicial: formatDate(new Date(new Date().setDate(new Date().getDate() - 2))),
        fechaFinal: formatDate(new Date()),
        respuesta: "0",
        sucursal: "0",
        doctor: "0",
        canal: "0"
    });

    return (
        <div>

            <FiltrosMetricas 
                showFilters={showFilters}
                filters={filters}
                setFilters={setFilters}/>
            
            <MetricsContactabilidadLayout
                filters={filters}/>

            <MetricsCitasIngresadasLayout 
                filtros={filters}/>

        </div>
    );
}