import { useState } from "react";
import { ModalCenter } from "../UtilitiesComponents/ModalCenter";
import { ModalImagePreview } from "./ModalImagePreview";

interface Props {
    urlImage: string;
    alt: string;
    className: string;
}

export function ImagePreview({ urlImage, alt, className }: Props) {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [showPreview, setShowPreview] = useState<boolean>(false);

    const onCloseModal = (id: number) => {
        setModalOpen(!modalOpen);
    }

    const openPreview = () => setShowPreview(!showPreview);

    return (
        <>
            <img 
                className={className}
                src={urlImage} 
                alt={alt}
                onClick={openPreview}/>

            {showPreview && (
            <ModalCenter onClose={onCloseModal}>
                <ModalImagePreview
                    urlImage="/diagram-msg.png"

                    openPreview={openPreview}/>
            </ModalCenter>
            )}
        </>
    );
}
