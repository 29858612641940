import axios from "axios";
import { SucursalResponse } from "../types/utils";

export class UtilsServices {
    private readonly url: string = "/api/utils";

    public async GetSucursalList(): Promise<SucursalResponse[]> {
        const response = await axios.get(`${this.url}/sucursales`);
        const { data } = response;
        return data;
    }
}