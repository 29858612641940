import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { MetricasCitasIngresadas } from '../../../types/metrics';

interface Props {
    metrics: MetricasCitasIngresadas;
}
export function BarChart({ metrics }: Props) {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            datalabels: {
                display: true,
                color: 'rgba(17, 24, 39, 1)',
            },
            legend: {
                position: 'top' as const,
            },
            title: {
            display: false,
            text: 'Citas Ingresadas',
            },
        },
    };

    const labels = ["Total", "Confirmadas", "Canceladas", "No Contesto", "No Validas"];

    const data = {
        labels,
        datasets: [
            {
                label: 'Citas',
                data: [
                    metrics?.total.totalCitas,
                    metrics?.confirmadas.totalCitas,
                    metrics?.canceladas.totalCitas,
                    metrics?.sinContestar.totalCitas,
                    metrics?.noValidas.totalCitas,
                ],
                backgroundColor: [
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(255, 99, 132, 0.5)',
                ],
                borderColor: [
                    'rgb(54, 162, 235)',
                    'rgb(75, 192, 192)',
                    'rgb(255, 99, 132)',
                    'rgb(255, 99, 132)',
                    'rgb(255, 99, 132)',
                ],
                borderWidth: 1
            },
        ],
    };

    return (
        <Bar 
            options={options}
            data={data}
            plugins={[ChartDataLabels]}/>
    );
}