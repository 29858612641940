import { BotonRegresar } from "../UtilitiesComponents";
import { ImagePreview } from "./ImagePreview";

export function DiagramConf() {

    return (
        <div>

            <div className="sm:flex my-8 sm:my-4">
                <ImagePreview
                    alt="Diagrama de flujo del proceso de envio de llamadas de confirmación" 
                    urlImage="/diagram-call.png"
                    className="w-[485px] h-[633px] cursor-pointer"/>
                <div className="my-2 sm:mx-4">
                    <h4>Flujo de envio de llamadas de confirmación</h4>
                    <p>Las llamadas de confirmacion son realizadas 48 horas antes de la hora de la cita. En este proceso se pueden realizarce 2 intentos de llamada, el primer intento es 48 hora antes y el segundo es 15 minutos depues del primer inteto.</p>
                    <p>La segunda llamada será realizada si el primer intento de llamda no fue contestado.</p>
                    <p>Estos son los pasos del proceso del envio de llamadas:</p>

                    <ul className="list-decimal">
                        <li>Se buscan las citas que estan 48 horas antes de la hora de esta.</li>
                        <li>El sistema crea la llamada y hace el intento de enviarlo. Si este resulta en fallo, se registra el evento y se termina el proceso.</li>
                        <li>El cliente contesta la llamada, dentro de esta el cliente podra elegir si cancelar o confirmar la cita. En el caso de que el cliente no eliga una opción dentro de los intento permitidos, el sistema terminara la llamada y marcara la cita con el status de <span>BUZON</span>.</li>
                        <li>Si el cliente elige una de las dos opciones, el status de la cita sera actuliazado dependiendo de lo que haya elegido.</li>
                    </ul>
                </div>
            </div>

            <div className="sm:flex my-10 sm:my-4">
                <ImagePreview
                    urlImage="/diagram-msg.png"
                    alt="Diagrama de flujo del proceso de envio de mensajes de confirmación"
                    className="w-[485px] cursor-pointer"/>
                <div className="my-2 sm:mx-4">
                    <h4>Flujo de envio de mensajes de confirmación</h4>
                    <p>Los mensajes de confirmacion son enviados sí después de que la cita no se haya confirmado mediante las llamdas de confirmación.</p>
                    <p>Dentro del proceso hay dos envios de mensajes, uno es 24 horas antes de la hora de la cita y otro 4 horas antes. </p>
                    <p>Si la cita es confiramdas con el primero mensajes, el segundo mensaje de confirmacion no es enviado pero es enviado un mensaje de notificacion 4 horas antes de la hora de la cita como recordatorio.</p>
                    <p>Estos son los pasos del proceso del envio de mensajes:</p>

                    <ul className="list-decimal">
                        <li>Se busacan las citas que estan 24 horas antes de la hora de esta.</li>
                        <li>El sistema crea le mensaje y hace el intento de enviarlo. Si este resulta en fallo, se registra el evento y se termina el proceso.</li>
                        <li>Una vez enviado el mensaje, el sistema esperara la respuesta del cliente para este primer intento.</li>
                        <li>Si la cita no ha sido confirmada con el primer intento de confirmación, el sistema enviará un segundo mensaje de confirmación 4 horas antes de la hora de la cita. Esto hará que el estado del primer mensaje de confirmación se actualice a <span>Sin Respuesta</span>.</li>
                        <li>El cliente tiene dos posibles respuestas para seleccionar en el mensaje de confirmación: Confirmar y Cancelar. Cualquier opción que el cliente eliga, acutalizará el status de la cita en cuestión.</li>
                    </ul>
                </div>
            </div>

            <div className="flex justify-center my-4">
                <BotonRegresar />
            </div>

        </div>
    );
}

