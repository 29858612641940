import { Table } from "@mui/joy";
import { MetricasContactabilidad } from "../../../types/metrics";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MessageIcon from '@mui/icons-material/Message';

interface Props {
    metricas: MetricasContactabilidad;
}

export function DataTable({ metricas }: Props) {

    const metricsTotales = metricas?.totales;
    const metricsIVR = metricas?.ivr;
    const metricsMensajes = metricas?.mensajes;

    return (
        <Table
            stickyHeader
            sx={(theme) => ({
                "th": { bgcolor: "#111827", color: "#fff", textAlign: "center" },
                "td": { textAlign: "center" }
            })}>
            <thead className="bg-slate-800 text-white rounded-md py-2 font-medium">
                <tr>
                    <th>Canal</th>
                    <th>Entregados</th>
                    <th>Confirmados</th>
                    <th>% Confirmados</th>
                    <th>Cancelados</th>
                    <th>% Cancelados</th>
                    <th>Sin Respuestas</th>
                    <th>% Sin Respuestas</th>
                    {/* <th>Esperando Respuesta</th>
                    <th>% Esperando Respuesta</th> */}
                    <th>Fallo</th>
                    <th>% Fallo</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <LocalPhoneIcon sx={{ color: "#111827"}}/>
                        IVR
                    </td>
                    <td>{metricsIVR?.entregados}</td>
                    <td className="font-bold">{metricsIVR?.confirmados}</td>
                    <td>{metricsIVR?.porcentajeConfirmados}%</td>
                    <td className="font-bold">{metricsIVR?.cancelados}</td>
                    <td>{metricsIVR?.porcentajeCancelados}%</td>
                    <td className="font-bold">{metricsIVR?.noContesto + metricsIVR?.esperandoRespuesta}</td>
                    <td>{metricsIVR?.porcentajeNoContesto + metricsIVR?.porcentajeEsperandoRespuesta}%</td>
                    {/* <td className="font-bold">{metricsIVR?.esperandoRespuesta}</td>
                    <td>{metricsIVR?.porcentajeEsperandoRespuesta}%</td> */}
                    <td className="font-bold">{metricsIVR?.fallo}</td>
                    <td>{metricsIVR?.porcentajeFallo}%</td>
                </tr>

                <tr>
                    <td><MessageIcon sx={{ color: "#111827"}}/>Mensajes de Whatsapp</td>
                    <td>{metricsMensajes?.entregados}</td>
                    <td className="font-bold">{metricsMensajes?.confirmados}</td>
                    <td>{metricsMensajes?.porcentajeConfirmados}%</td>
                    <td className="font-bold">{metricsMensajes?.cancelados}</td>
                    <td>{metricsMensajes?.porcentajeCancelados}%</td>
                    <td className="font-bold">{metricsMensajes?.noContesto + metricsMensajes?.esperandoRespuesta}</td>
                    <td>{metricsMensajes?.porcentajeNoContesto + metricsMensajes?.fallo}%</td>
                    {/* <td className="font-bold">{metricsMensajes?.esperandoRespuesta}</td>
                    <td>{metricsMensajes?.porcentajeEsperandoRespuesta}%</td> */}
                    <td className="font-bold">{metricsMensajes?.fallo}</td>
                    <td>{metricsMensajes?.porcentajeFallo}%</td>
                </tr>

                <tr>
                    <td>Total</td>
                    <td>{metricsTotales?.entregados}</td>
                    <td className="font-bold">{metricsTotales?.confirmados}</td>
                    <td>{metricsTotales?.porcentajeConfirmados}%</td>
                    <td className="font-bold">{metricsTotales?.cancelados}</td>
                    <td>{metricsTotales?.porcentajeCancelados}%</td>
                    <td className="font-bold">{metricsTotales?.noContesto + metricsTotales?.esperandoRespuesta}</td>
                    <td>{metricsTotales?.porcentajeNoContesto + metricsTotales?.porcentajeEsperandoRespuesta}%</td>
                    {/* <td className="font-bold">{metricsTotales?.esperandoRespuesta}</td>
                    <td>{metricsTotales?.porcentajeEsperandoRespuesta}%</td> */}
                    <td className="font-bold">{metricsTotales?.fallo}</td>
                    <td>{metricsTotales?.porcentajeFallo}%</td>
                </tr>
            </tbody>
        </Table>
    );
}