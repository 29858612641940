import { Table } from "@mui/joy";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CitaServices } from "../../services"; 
import { CsvData } from "../../types";
import { ModalCenter } from "../UtilitiesComponents/ModalCenter";
import { DetallesCita } from "./DetallesCita";
import { PaginationButtons } from "../UtilitiesComponents/PaginationButtons";
import { LoadingComponent } from "../UtilitiesComponents/LoadingComponent";
import { ElementsNotFound } from "../UtilitiesComponents/ElementsNotFound";

interface Props {
    listCitas: Array<CsvData>;
    nextPage: boolean;
    setPageNumber: Dispatch<SetStateAction<number>>;
    pageNumber: number;
    dataLoading: boolean
}

export function ConsultaCita({ listCitas, nextPage, setPageNumber, pageNumber, dataLoading }: Props) {

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [idCita, setIdCita] = useState<number>(0);

    const onCloseModal = (id: number) => {
        setModalOpen(!modalOpen);
        setIdCita(id);
    }

    return (
        <div>

        <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200 my-4">
            <Table 
                stickyHeader
                sx={(theme) => ({
                    "th": { bgcolor: "#111827", color: "#fff", textAlign: "center" },
                    "td": { textAlign: "center" }
                })}>
                <thead className="bg-slate-800 text-white rounded-md py-2 font-medium">
                    <tr >
                        <th className="w-28">Sucursal</th>
                        <th className="w-28">Fecha</th>
                        <th className="w-28">Hora</th>
                        <th className="w-28">Doctor</th>
                        <th className="w-28">Cliente</th>
                        <th className="w-28">Motivo</th>
                        <th className="w-28">Telefono</th>
                        <th className="w-28">Accion</th>
                    </tr>
                </thead>
                {!dataLoading && (
                <tbody>
                {listCitas.map((value, index) => (
                    <tr key={index} className={`hover:bg-slate-100`}>
                        <td>{value.sucursal}</td>
                        <td>{value.fecha}</td>
                        <td>{value.hora}</td>
                        <td>{value.doctor}</td>
                        <td>{value.cliente}</td>
                        <td>{value.motivo}</td>
                        <td>{value.telefono}</td>
                        <td>
                            <button onClick={() => onCloseModal(value.id)}>Detalles</button>
                        </td>
                    </tr>
                ))}
                </tbody>
                )}
            </Table>

            {dataLoading && (
                <div className="grid justify-items-center w-full h-[600px]">
                    <LoadingComponent />
                </div>

            )}

            {listCitas.length == 0 && (
                <ElementsNotFound />
            )}

            {modalOpen && (
                <ModalCenter onClose={onCloseModal}>
                    <DetallesCita 
                        idCita={idCita}
                        setClose={setModalOpen}
                        close={modalOpen}/>
                </ModalCenter>
            )}
        </div>

        <div className="flex justify-center">
            <PaginationButtons 
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                nextPage={nextPage} />
        </div>

        </div>
    );
}