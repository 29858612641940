import { useEffect, useState } from "react"
import { CitasDataPagination, CsvData, FilterCitas, ShowFilters } from "../types";
import { ContadorCitas } from "./ConsultaComponents/ContadorCitas";
import { CSVLink } from "react-csv";
import { filtrosCitas, filtrosRespuestas } from "../services/filters";
import { formatDate } from "../utilities/formatDate";
import { SearchFilter, FiltrosConsultaRespuesta } from "./FiltrosComponents";
import { CitaServices } from "../services";
import { ConsultaCita, ConsultaRespuestas } from "./ConsultaComponents";
import { TabContainer } from "./NavigationComponents/TabContainer";


export function ConsultaComponent() {
    const citaServices = new CitaServices(); 

    const showFilters: ShowFilters = {
        fechas: true,
        sucursales: true,
        canales: true,
        respuestas: true,
        doctores: true,
    };

    // test pagination
    const [pageNumberCitas, setPageNumberCitas] = useState<number>(1);
    const [pageNumberRespuestas, setPageNumberRespuestas] = useState<number>(1);
    const [dataLoadingCitas, setDataLoadingCitas] = useState<boolean>(true);
    const [dataLoadingRespuestas, setDataLoadingRespuestas] = useState<boolean>(true);

    const [listCitas, setListCitas] = useState<CitasDataPagination>({
        totalRows: 0,
        currentPage: 1,
        nextPage: false,
        data: []
    });
    const [listResponse, setListResponse] = useState<CitasDataPagination>({
        totalRows: 0,
        currentPage: 1,
        nextPage: false,
        data: []
    });

    const [search, setSearch] = useState<string>(""); 
    const [filters, setFilters] = useState<FilterCitas>({
        fechaInicial: formatDate(new Date(new Date().setDate(new Date().getDate() - 2))),
        fechaFinal: formatDate(new Date()),
        respuesta: "0",
        sucursal: "0",
        doctor: "0",
        canal: "0"
    });

    let listRespuestasFiltered: Array<CsvData>  = []; 
    let listCitasFiltered: Array<CsvData>  = []; 

    useEffect(() => {
        setDataLoadingRespuestas(true);

        citaServices.getListResponses(filters.fechaInicial, filters.fechaFinal, pageNumberRespuestas)
            .then(response => {
                setListResponse(response.data);
                setDataLoadingRespuestas(false);
            })
    }, [filters.fechaInicial, filters.fechaFinal, pageNumberRespuestas]);

    useEffect(() => {
        setDataLoadingCitas(true);

        citaServices.GetListCitas(filters.fechaInicial, filters.fechaFinal, pageNumberCitas)
            .then(response => {
                setListCitas(response.data);
                setDataLoadingCitas(false);
            })
    }, [filters.fechaInicial, filters.fechaFinal, pageNumberCitas]);


    listRespuestasFiltered = listResponse.data.length > 0 ? filtrosRespuestas(listResponse.data, filters, search) : [];
    listCitasFiltered = listCitas.data.length > 0 ? filtrosCitas(listCitas.data, filters, search) : [];

    const csvData = [
        ["Sucursal", "Fecha", "Hora", "Doctor", "Cliente", "Motivo", "Telefono", "Fecha de Respuesta", "Respuesta", "Canal"],
        ...listRespuestasFiltered.map((csv) => [
        csv.sucursal,
        csv.fecha,
        csv.hora,
        csv.doctor,
        csv.cliente,
        csv.motivo,
        csv.telefono,
        csv.fechaRespuesta,
        csv.respuesta,
        csv.canal
    ])];

    const onClickExportCsvRespuestas = async () => {
        try {
            const response = await citaServices.exportRespustasCsv(filters.fechaInicial, filters.fechaFinal)

            // Crear un enlace temporal para descargar el archivo
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `respuestas-${filters.fechaInicial}-${filters.fechaFinal}.csv`); // Nombre del archivo
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error descargando el archivo', error);
        }
    }

    return (
        <div>
            <h1 className="font-semibold text-3xl text-slate-900 my-3">Consulta de Respuestas</h1>
            <SearchFilter 
                searchValue={search}
                setSearchValue={setSearch}/>

            <FiltrosConsultaRespuesta 
                showFilters={showFilters}
                filters={filters}
                setFilters={setFilters}/>

            <ContadorCitas 
                totalCitasRows={listCitas.totalRows}
                totalRespuestasRows={listResponse.totalRows}
                listaRespuestas={listRespuestasFiltered}
                listaCitas={listCitasFiltered}/>

            {/* <CSVLink 
                data={csvData} 
                enclosingCharacter="" 
                filename="data_citas.csv">
                <button className="w-fit bg-gray-900 text-white py-1 px-3 rounded-lg font-medium">
                    Exportar a CSV
                </button>
            </CSVLink> */}

            <button className="w-fit bg-gray-900 text-white py-1 px-3 rounded-lg font-medium"
                onClick={onClickExportCsvRespuestas}>
                Exportar Respuestas a CSV
            </button>

            <TabContainer 
                citasComponent={
                    <ConsultaRespuestas 
                        dataLoading={dataLoadingRespuestas}
                        setPageNumber={setPageNumberRespuestas}
                        pageNumber={pageNumberRespuestas}
                        nextPage={listResponse.nextPage}
                        listCitas={listRespuestasFiltered}/>}
                respuestasComponent={
                    <ConsultaCita 
                        dataLoading={dataLoadingCitas}
                        setPageNumber={setPageNumberCitas}
                        pageNumber={pageNumberCitas}
                        nextPage={listCitas.nextPage}
                        listCitas={listCitasFiltered}/>
                }/>
        </div>
    )
}