import React, { useState } from 'react';
import Papa from 'papaparse';
import { CSVLink } from 'react-csv';
import { Table } from '@mui/joy';

interface Props {
    //csvFile: File;
    csvData: unknown[];
    csvHeaders: string[];
}

export function CsvViewer({ csvData, csvHeaders }: Props) {
    return (
        <div>
        {csvData.length > 0 && (
            <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200 my-4">
                <Table 
                    sx={(theme) => ({
                        "th": { bgcolor: "#111827", color: "#fff" , textAlign: "center"},
                        "td": { textAlign: "center" }
                    })}>
                    <thead>
                    <tr>
                        <th className="text-center">Sucursal</th>
                        <th>Fecha</th>
                        <th>Hora</th>
                        <th>Doctor</th>
                        <th>Cliente</th>
                        <th>Motivo</th>
                        <th>Telefono</th>
                    </tr>
                    </thead>
                    <tbody>
                    {csvData.map((row, index) => (
                        <tr key={index} className={`hover:bg-slate-100`}>
                        {csvHeaders.map((header) =>(
                            <td key={header}>{row[header]}</td>
                        ))}
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        )}
        </div>
    );
};
