import axios from "axios";

export class CallServices {
    private readonly url: string = "/api/call";

    public SendCsvFile(csvFile: File) {
        return axios.post(
            this.url + "/crear-llamada",
            { "csvFile": csvFile },
            { headers: {'Content-Type': 'multipart/form-data'} }
        );
    }

}