import { BotonRegresar } from "../UtilitiesComponents";

export function CodigosError() {

    return (
        <div>
            <div>
                <h4>Errores que pueden ocurrir al enviar mensajes de confirmación</h4>
                <p>Dentro del proceso de envió de mensajes de confirmación pueden ocurrir dos tipos de errores que son representados con los siguientes códigos:</p>
                <ul className="list-disc">
                    <li className="mb-10">
                        <h5>Código de error 63024</h5>
                        <p>Este error ocurre cuando se intenta enviar un mensaje a un número de teléfono que no cuenta con una cuenta de Whatsapp.</p>
                        <p>La creación del mensaje se realiza correctamente y Twilio lo procesa de manera normal. Hasta llegar al paso en el que Twilio tiene enviar el mensaje al cliente es cuando se detecta el error y se marca en los registros de mensajes enviados en la página de Twilio.</p>
                    </li>
                    <li className="mb-10">
                        <h5>Código de error 21211</h5>
                        <p>Este error ocurre cuando se está creando un mensaje en el sistema, pero el número telefónico del cliente tiene un formato incorrecto.</p>
                        <p>El formato correcto que debe tener el número telefónico almacenado en la base de datos es el siguiente:</p>
                        <ul className="list-disc">
                            <li>Tiene que ser un número telefónico de 10 dígitos.</li>
                            <li>De preferencia guardar el número en la base de datos sin espacios ni comillas.</li>
                            <li>
                                <p className="mb-0">Solo guardar un número de teléfono. Evitar guardar más de un teléfono dividiéndolos con algun carácter especial.</p>
                                <p>Por ejemplo: <span className="font-semibold">0123456789/0987654321</span> o <span className="font-semibold">0123456789-0987654321</span></p>
                            </li>
                        </ul>
                        <p>Este error también puede ocurrir si no se proporciona ningún número de teléfono.</p>
                        <p>El registro de este tipo de error se puede encontrar dentro de los logs del sistema almacenados en el servidor.</p>
                    </li>
                </ul>
            </div>

            <div className="flex justify-center my-4">
                <BotonRegresar />
            </div>
        </div>
    );
}