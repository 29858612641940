import { createPortal } from "react-dom";

interface Props {
    children: any
    onClose: any
}

export function ModalCenter({children, onClose}: Props) {
    const modalRoot = document.getElementById("modal-root")!;

    return createPortal(
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded shadow-lg">
                {children}
            </div>
        </div>
    , modalRoot);
}