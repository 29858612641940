
export enum EstadoCita {
    CONFIRMADA = "Confirmada",
    CANCELADA = "Cancelada",
    NOCONTESTO = "No Contesto",
    SINCONTACTAR = "Sin Contactar"
}

// Esto es provisional
export enum Sucursales {
    LINCOLN = "Lincoln",
    SANTACATARINA = "Santa Catarina",
    SANNICOLAS = "San Nicolas",
    CENTRO = "Centro",
    YGRIEGA = "Y Griega",
    LAFE = "La Fe"
}

export const EstadoCitasList = Object.values(EstadoCita);
export const SucursalesList = Object.values(Sucursales);