import { useNavigate } from "react-router-dom";

export function BotonRegresar() {
    const navigate = useNavigate();

    return (
        <button 
            className="w-fit h-[32px] bg-gray-600 text-white py-1 px-3 rounded-lg font-medium"
            onClick={() => navigate(-1)}>
            Regresar
        </button>
    );
}