import { Tab, TabList, TabPanel, Tabs, tabClasses } from "@mui/joy";
import { ReactNode, useState } from "react";
//import { Tab } from "./Tab";

interface Props {
    citasComponent: ReactNode;
    respuestasComponent: ReactNode;
}

export function TabContainer({ citasComponent, respuestasComponent }: Props) {

    return (
    <Tabs aria-label="tabs" defaultValue={0} sx={{ 
            bgcolor: 'transparent',
            marginTop: "16px",
        }}>
      <TabList
        disableUnderline
        sx={{
            p: 0.5,
            gap: 0.5,
            borderRadius: 'xl',
            bgcolor: 'background.level1',
            [`& .${tabClasses.root}[aria-selected="true"]`]: {
                boxShadow: 'sm',
                bgcolor: 'background.surface',
            },
            color: "#9ca3af",
            fontWeight: 600
        }}
      >
        <Tab disableIndicator>Respuestas</Tab>
        <Tab disableIndicator>Citas</Tab>
      </TabList>
      <TabPanel value={0}>
        {citasComponent}
      </TabPanel>
      <TabPanel value={1}>
        {respuestasComponent}
      </TabPanel>
    </Tabs>
    );
}