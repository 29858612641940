import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface Props {
    urlImage: string | undefined;
    openPreview: () => void;
}

export function ModalImagePreview({ urlImage, openPreview }: Props) {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-400">
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="z-10">
                <div
                    className="sm:relative max-h-screen max-w-screen overflow-auto touch-auto sm:p-8 bg-white sm:rounded-xl" >
                    <button
                        className="absolute right-2 top-1"
                        onClick={openPreview}>
                        <CloseRoundedIcon />
                    </button>
                    <img 
                        src={urlImage} 
                        alt="Imagen" 
                        className={`transition-transform min-w-max`} />
                </div>
            </div>
        </div>
    );
}