import { useEffect, useState } from "react";
import { MetricasCitasIngresadas } from "../../../types/metrics";
import { MetricsServices } from "../../../services/metricsServices";
import { FilterCitas } from "../../../types";
import { DataTable } from "./DataTable";
import { BarChart } from "./BarChart";
import { SinDatosTitle } from "../MetricsContactabilidad";
import { LoadingComponent } from "../../UtilitiesComponents/LoadingComponent";

interface Props {
    filtros: FilterCitas;
}

export function MetricsCitasIngresadasLayout({ filtros }: Props) {
    const metricsService = new MetricsServices();

    const [metrics, setMetrics] = useState<MetricasCitasIngresadas>();
    const [dataLoading, setDataLoading] = useState<boolean>(true);

    const thereIsNotData = metrics?.total.totalCitas === 0;

    const onClickExportCsvRespuestas = async () => {
        try {
            const response = await metricsService.ExportCitasIngresadasData(filtros.fechaInicial, filtros.fechaFinal, parseInt(filtros.sucursal))

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `citas-ingresadas-${filtros.fechaInicial}-${filtros.fechaFinal}.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error descargando el archivo', error);
        }
    }

    useEffect(() => {
        setDataLoading(true);

        const metrics = metricsService.GetMetricsCitasIngresadas(filtros.fechaInicial, filtros.fechaFinal, parseInt(filtros.sucursal))
            .then(data => setMetrics(data));

        Promise.all([metrics])
            .then((_) => setDataLoading(false));
    }, [filtros]);

    return (
        <div>
            {dataLoading 
            ? <LoadingComponent />
            : 
            <>
            <div className="flex items-center justify-between">
                <p className="font-semibold text-2xl mt-4">Citas ingresadas</p>

                <button className={`w-fit h-[32px] ${thereIsNotData ? "bg-gray-400" : "bg-gray-900"} text-white py-1 px-3 rounded-lg font-medium`}
                    disabled={thereIsNotData}
                    onClick={onClickExportCsvRespuestas}>
                    Exportar a CSV
                </button>
            </div>

            {thereIsNotData
                ? <SinDatosTitle />
                : 
                <>
                    <div className="flex justify-center w-full h-[350px] my-4">
                        {metrics && (
                            <div className="w-[600px] h-[600px]">
                                <BarChart 
                                metrics={metrics}/>
                            </div>
                        )}
                    </div>

                    <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200 my-4">
                        {metrics && (
                            <DataTable 
                                metrics={metrics}/>
                        )}
                    </div>
                </>
            }
            </>
        }

        </div>
    );
}