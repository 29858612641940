import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { CitaServices } from "../../services";
import { Cita, CsvData } from "../../types";

interface Props {
    setClose: Dispatch<SetStateAction<boolean>>;
    close: boolean;
    idCita: number; 
}

export function DetallesCita({ setClose, close, idCita }: Props) {
    const citaServices = new CitaServices(); 
    const [cita, setCita] = useState<Cita>()

    useEffect(() => {
        citaServices.GetCitaRespuestas(idCita)
            .then(res => {
                setCita(res.data)
            })
    }, []);

    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
        <div className="bg-white rounded-lg overflow-hidden w-[800px]">
                <div className="flex justify-between items-center p-3 bg-[#f4f4f4] border-b border-gray-200">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Detalles de la cita {idCita}</h3>
                    <button className="text-gray-400 hover:text-gray-500"
                        onClick={() => setClose(!close)}>
                        <XIcon className="h-6 w-6" />
                    </button>
                </div>
            <div className="p-6">
            <div className="grid grid-cols-2 gap-6">
                <div>
                    <div className="mb-4">
                        <h4 className="text-sm font-semibold text-gray-700">Nombre paciente</h4>
                        <p className="mt-1 text-sm text-gray-900">{cita?.cliente}</p>
                    </div>
                    <div className="mb-4">
                        <h4 className="text-sm font-semibold text-gray-700">Celular paciente</h4>
                        <p className="mt-1 text-sm text-gray-900">{cita?.telefono}</p>
                    </div>
                    <div>
                        <h4 className="text-sm font-semibold text-gray-700">Profesional</h4>
                        <p className="mt-1 text-sm text-gray-900">{cita?.doctor}</p>
                    </div>
                </div>

                <div>
                    <div className="mb-4">
                        <h4 className="text-sm font-semibold text-gray-700">Procedimiento</h4>
                        <p className="mt-1 text-sm text-gray-900">{cita?.motivo}</p>
                    </div>
                    <div className="mb-4">
                        <h4 className="text-sm font-semibold text-gray-700">Fecha reserva</h4>
                        <p className="mt-1 text-sm text-gray-900">{`${cita?.fecha} ${cita?.hora}`}</p>
                    </div>
                    <div className="mb-4">
                        <h4 className="text-sm font-semibold text-gray-700">Sucursal</h4>
                        <p className="mt-1 text-sm text-gray-900">{cita?.sucursal}</p>
                    </div>
                </div>
            </div>
            <div className="mt-6 text-gray-700">
                <h4 className="text-lg font-semibold text-gray-700 mb-2">Historial de contactos realizados</h4>
                <div className="overflow-auto max-h-[200px] rounded-lg border-slate-200 my-2">
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th>Fecha contacto</th>
                                <th>Canal</th>
                                <th>Respuesta</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cita?.listRespuestas.map((respuesta, index) => (
                                <tr>
                                    <td>{respuesta.fechaRespuesta}</td>
                                    <td>{respuesta.canal}</td>
                                    <td>{respuesta.respuesta}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
            </div>
            <div className="flex justify-end p-6 border-t border-gray-200">
            </div>
        </div>
        </div>
    )
}

function XIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  )
}
