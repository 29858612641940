import { useState } from "react";
import Papa from 'papaparse';
import { CsvViewer } from "./CsvViewer";
import { CallServices, MessageServices } from "../services";
import { ModalCenter } from "./UtilitiesComponents/ModalCenter";
import { DetallesCita } from "./ConsultaComponents/DetallesCita";

export function Home() {
    const [csvData, setCsvData] = useState<unknown[]>([]);
    const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
    const [csvFile, setCsvFile] = useState<File | null>(null);

    // manage Loading state
    const [isLoadingCall, setIsLoadingCall] = useState(false);
    const [isLoadingMessage, setIsLoadingMessage] = useState(false);
    const [messageCall, setMessageCall] = useState("");
    const [messageMessage, setMessageMessage] = useState("");

    const callService = new CallServices();
    const messageService = new MessageServices();

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            setCsvFile(file);
            Papa.parse(file, {
                complete: (result) => {
                    if (result.data.length > 0) {
                        setCsvHeaders(Object.keys(result.data[0]));
                        setCsvData(result.data);
                    }
                },
                header: true,
            });
        }
    };

    const handleLlamadas = (event: React.MouseEvent<HTMLButtonElement>) => {

        if(csvFile !== null) {
            setIsLoadingCall(true);
            callService.SendCsvFile(csvFile)
                .then(response => {
                    if(response.status === 200) {
                        console.log(response.data);
                        setIsLoadingCall(false);
                        setMessageCall("Llamadas realizadas con exito");
                    }
                })
                .catch(error => {
                    setIsLoadingCall(false);
                    console.error(error)
                });
        } else {
            console.log("No se ha cargado un archivo");
        }
    }

    const handleMensajes = (event: React.MouseEvent<HTMLButtonElement>) => {

        //if(csvFile !== null) {
            setIsLoadingMessage(true);
            messageService.SendCsvFile(csvFile)
                .then(response => {
                    if(response.status === 200) {
                        console.log(response.data);
                        setIsLoadingMessage(false);
                        setMessageMessage("Mensajes enviados con exito");
                    }
                })
                .catch(error => {
                    console.error(error)
                    setIsLoadingMessage(false);
                });
        //} else {
        //    console.log("No se ha cargado un archivo");
        //}
    }

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const onCloseModal = () => {
        setModalOpen(!modalOpen);
    }
     
    return (
        <div>
            <h1 className="font-semibold text-slate-900">Cargar las citas</h1>
            <form className="flex flex-col">
                <label className="font-bold text-gray-800 my-2">Subir archivo</label>
                <input type="file" onChange={handleFileUpload}/>

                <div className="flex flex-col gap-2 text-gray-200 my-3">
                    <div className="flex gap-3">
                        <button 
                            className="bg-blue-900 w-40 rounded-full p-1 hover:bg-blue-950"
                            onClick={handleLlamadas}
                            type="button">
                            Realizar llamadas
                        </button>
                        { isLoadingCall 
                        ? <div className="w-4 h-4 my-1 bg-blue-500 animate-spin duration-300"></div>
                        : <p className="font-semibold italic text-green-600 my-1">{ messageCall }</p>
                        }
                    </div>
                    <div className="flex gap-3">
                        <button 
                            className="bg-emerald-900 w-40 rounded-full p-1 hover:bg-emerald-950"
                            onClick={handleMensajes}
                            type="button">
                            Enviar Mensajes
                        </button>
                        {  isLoadingMessage
                        ? <div className="w-4 h-4 my-1 bg-blue-500 animate-spin duration-300"></div>
                        : <p className="font-semibold italic text-green-600 my-1">{ messageMessage }</p> 
                        }
                        
                    </div>
                </div>
            </form>
            <CsvViewer 
                csvData={csvData} 
                csvHeaders={csvHeaders}/>
            <button onClick={onCloseModal}>Abrir modal</button>
            {modalOpen && (
                <ModalCenter onClose={onCloseModal}>
                    <DetallesCita 
                        setClose={setModalOpen}
                        close={modalOpen}/>
                </ModalCenter>
            )}
        </div>
    );
}
