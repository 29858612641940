import axios from "axios";
import { CitasDataPagination } from "../types";

export class CitaServices {
    private readonly url: string = "/api/cita";

    public async GetListCitas(fechaInicio: string, fechaFin: string, pageNumber: number) {
        const response = await axios.get<CitasDataPagination>(this.url, { params: { fechaInicio, fechaFin, pageNumber } });
        return response;
    }

    public async GetCitaRespuestas(id: number) {
        const response = await axios.get(this.url + `/${id}`);
        console.log(response.data)
        return response;
    }

    public getListResponses(fechaInicio: string, fechaFin: string, pageNumber: number) {
        return axios.get(`${this.url}/responses`, { params: { fechaInicio, fechaFin, pageNumber } });
    }

    public async exportRespustasCsv(fechaInicio: string, fechaFin: string) {
        return await axios.get(`${this.url}/export-respuestas`, { 
            params: { fechaInicio, fechaFin },
            responseType: 'blob'
        });
    }
}