import { Table } from "@mui/joy";
import { MetricasCitasIngresadas } from "../../../types/metrics";

interface Props {
    metrics: MetricasCitasIngresadas;
}

export function DataTable({ metrics }: Props) {
    return (
        <Table
            stickyHeader
            sx={(theme) => ({
                "th": { bgcolor: "#111827", color: "#fff", textAlign: "center" },
                "td": { textAlign: "center" }
            })}>
            <thead className="bg-slate-800 text-white rounded-md py-2 font-medium">
                <tr>
                    <th>Total Citas</th>
                    <th>Confirmadas</th>
                    <th>Canceladas</th>
                    <th>No Contesto</th>
                    <th>En Espera</th>
                    <th>Con Fallo</th>
                    <th className="border-l-2 border-l-zinc-50">Validas</th>
                    <th>No Validas</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{metrics?.total.totalCitas}</td>
                    <td>{metrics?.confirmadas.totalCitas}</td>
                    <td>{metrics?.canceladas.totalCitas}</td>
                    <td>{metrics?.sinContestar.totalCitas}</td>
                    <td>{metrics?.enEspera.totalCitas}</td>
                    <td>{metrics?.conFallo.totalCitas}</td>
                    <td className="border-l">{metrics?.validas.totalCitas}</td>
                    <td>{metrics?.noValidas.totalCitas}</td>
                </tr>

                <tr>
                    <td>{metrics?.total.porcentajeCitas}%</td>
                    <td>{metrics?.confirmadas.porcentajeCitas}%</td>
                    <td>{metrics?.canceladas.porcentajeCitas}%</td>
                    <td>{metrics?.sinContestar.porcentajeCitas}%</td>
                    <td>{metrics?.enEspera.porcentajeCitas}%</td>
                    <td>{metrics?.conFallo.porcentajeCitas}%</td>
                    <td className="border-l">{metrics?.validas.porcentajeCitas}%</td>
                    <td>{metrics?.noValidas.porcentajeCitas}%</td>
                </tr>
            </tbody>
        </Table>
    );
}