import { Contactabilidad } from "../../../types/metrics";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from "react-chartjs-2";

interface Props {
    metricsTotales: Contactabilidad;
}

export function PieChart({ metricsTotales }: Props) {

    ChartJS.register(ArcElement, Tooltip, Legend);

    const data = {
        labels: ["Confirmado", "Cancelado", "No Contesto"],
        datasets: [
            {
                label: "Número de respuestas",
                data: [
                    metricsTotales?.confirmados,
                    metricsTotales?.cancelados,
                    metricsTotales?.noContesto
                ],
                backgroundColor: [
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                ],
                borderColor: [
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <Pie data={data} />
    );
}