import { Link } from "react-router-dom";

export function TopciMenu() {
    return (
        <div>
            <h3>Temas</h3>
            <ul className="list-disc">
                <li>
                    <Link to="proceso-confirmacion">
                        Proceso de confirmación de citas.
                    </Link>
                </li>
                <li>
                    <Link to="codigos-error">
                        Posibles códigos de error al enviar mensajes de confirmación.
                    </Link>
                </li>
            </ul>
        </div>
    );
}