import { ConsultaComponent } from "./components/ConsultaComponent";
import { DiagramConf, TopciMenu, CodigosError } from "./components/HelpComponents";
import { Home } from "./components/Home";
import { MetricasLayout } from "./components/MetricasComponents/MetricasLayout";

const AppRoutes = [
  {
    index: true,
    element: <ConsultaComponent />
  },
  {
    path: '/consulta',
    element: <Home />
  },
  {
    path: "/metricas",
    element: <MetricasLayout />
  },
  {
    path: "/ayuda",
    element: <TopciMenu />
  },
  {
    path: "/ayuda/proceso-confirmacion",
    element: <DiagramConf />
  },
  {
    path: "/ayuda/codigos-error",
    element: <CodigosError />
  },
];

export default AppRoutes;
