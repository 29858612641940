import axios from "axios";
import { MetricasCitasIngresadas, MetricasContactabilidad } from "../types/metrics";

export class MetricsServices {

    private readonly url: string = "/api/metricas";

    public async GetMetricsContactabilidad(fechaInicio: string, fechaFin: string, sucursalId: number): Promise<MetricasContactabilidad> {
        const response = await axios
            .get<MetricasContactabilidad>(
                `${this.url}/contactabilidad`,
                { 
                    params: { fechaInicio, fechaFin, sucursalId }
                }
            );

        const { data } = response;

        return data;
    }

    public async GetMetricsCitasIngresadas(fechaInicio: string, fechaFin: string, sucursalId: number): Promise<MetricasCitasIngresadas>{
        const response = await axios
            .get<MetricasCitasIngresadas>(
                `${this.url}/citas`,
                {
                    params: { fechaInicio, fechaFin, sucursalId }
                }
            );
        const { data } = response;
        return data;
    }

    public async ExportContactabilidadData(fechaInicio: string, fechaFin: string, sucursalId: number) {
        return await axios.get(`${this.url}/contactabilidad/export`, {
            params: { fechaInicio, fechaFin, sucursalId }
        });
    }

    public async ExportCitasIngresadasData(fechaInicio: string, fechaFin: string, sucursalId: number) {
        return await axios.get(`${this.url}/citas/export`, {
            params: { fechaInicio, fechaFin, sucursalId }
        });
    }
}