import { useEffect, useState } from "react";
import { MetricsServices } from "../../../services/metricsServices";
import { FilterCitas } from "../../../types";
import { MetricasContactabilidad } from "../../../types/metrics";
import { DataTable } from "./DataTable";
import { PieChart } from "./PieChart";
import { SinDatosTitle } from "./SinDatosTitle";
import { LoadingComponent } from "../../UtilitiesComponents/LoadingComponent";

interface Props {
    filters: FilterCitas;
}

export function MetricsContactabilidadLayout({ filters }: Props) {
    const [metricas, setMetricas] = useState<MetricasContactabilidad>();
    const [dataLoading, setDataLoading] = useState<boolean>(true);

    const metricsService = new MetricsServices();

    const thereIsNotData = metricas == undefined || metricas.totalRespuestas === 0;

    const onClickExportCsvRespuestas = async () => {
        try {
            const response = await metricsService.ExportContactabilidadData(filters.fechaInicial, filters.fechaFinal, parseInt(filters.sucursal))

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `contactabilidad-${filters.fechaInicial}-${filters.fechaFinal}.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error descargando el archivo', error);
        }
    }

    useEffect(() => {
        setDataLoading(true);

        const metrics = metricsService.GetMetricsContactabilidad(filters.fechaInicial, filters.fechaFinal, parseInt(filters.sucursal))
            .then(data => setMetricas(data));

        Promise.all([metrics])
            .then((_) => setDataLoading(false));
    }, [filters]);

    return (
        <div>
            {dataLoading
            ? <LoadingComponent/>
            :
            <>
            <div className="flex items-center justify-between">
                <p className="font-semibold text-2xl mt-4">Contactabilidad</p>

                <button className={`w-fit h-[32px] ${thereIsNotData ? "bg-gray-400" : "bg-gray-900"} text-white py-1 px-3 rounded-lg font-medium`}
                    disabled={thereIsNotData}
                    onClick={onClickExportCsvRespuestas}>
                    Exportar a CSV
                </button>
            </div>

            {thereIsNotData
                ? <SinDatosTitle /> 
                :
                <>
                    <div className="flex justify-center w-full h-[400px] my-4">
                        <div className="w-[400px] h-[400px]">
                        {metricas?.totales && (
                            <PieChart
                                metricsTotales={metricas?.totales}/>
                        )}
                        </div>
                    </div>

                    <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200 my-4">
                        {metricas && (
                            <DataTable 
                                metricas={metricas}/>
                        )}
                    </div>
                </>
            }
            </>
            }

        </div>
    );
}