import axios from "axios";

export class MessageServices {
    private readonly url: string = "/api/message";

    public SendCsvFile(csvFile: File) {
        return axios.post(
            this.url + "/crear-mensaje",
            { "csvFile": csvFile },
            { headers: {'Content-Type': 'multipart/form-data'} }
        );
    }
}