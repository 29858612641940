import { CsvData } from "../../types";


interface Props {
    listaCitas: Array<CsvData>
    listaRespuestas: Array<CsvData>;
    totalCitasRows: number;
    totalRespuestasRows: number;
}

export function ContadorCitas({ listaCitas, listaRespuestas, totalCitasRows, totalRespuestasRows }: Props) {

    const agendada = listaCitas.filter(cita => cita.status == null).length;
    const citasConfirmadas = listaCitas.filter(cita => cita.status === "Confirmada").length;
    const citasCanceladas = listaCitas.filter(cita => cita.status === "Cancelada").length;
    const noContestadas = listaCitas.filter(cita => cita.status === "No Contesto").length;

    return (
        <div className="flex gap-2 w-full h-full sm:h-8 my-2 flex-wrap">

            <div className="rounded-md w-fit px-3 py-1 shadow-sm">
                <p className="font-semibold align-middle m-0">Citas: { totalCitasRows }</p>
            </div>

            <div className="rounded-md w-fit px-3 py-1 shadow-sm">
                <p className="font-semibold align-middle m-0">Respuestas: { totalRespuestasRows }</p>
            </div>

            <div className="rounded-md w-fit px-3 py-1 shadow-sm">
                <p className="font-semibold align-middle m-0">Agendadas: { agendada }</p>
            </div>

            <div className="rounded-md w-fit px-3 py-1 shadow-sm">
                <p className="font-semibold align-middle m-0">Confirmadas: { citasConfirmadas }</p>
            </div>

            <div className="rounded-md w-fit px-3 py-1 shadow-sm">
                <p className="font-semibold align-middle m-0">Canceladas: { citasCanceladas }</p>
            </div>

            <div className="rounded-md w-fit px-3 py-1 shadow-sm">
                <p className="font-semibold align-middle m-0">Sin Contestar: { noContestadas }</p>
            </div>

        </div>
    );
}