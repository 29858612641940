import { CsvData, FilterCitas } from "../types";


export const filtrosRespuestas = (list: Array<CsvData>, filtros: FilterCitas, search: string) => {
    return list.filter(cita => {
            // filtro por respuesta
            if(filtros.respuesta !== "0"){
                if(cita.respuesta != filtros.respuesta){
                    return false;
                }
            }

            // filtro por sucursal
            const filterSucursal = filtros.sucursal.toLowerCase();
            const citaSucursal = cita.sucursal.toLowerCase();
            if(filterSucursal != "0") {
                if(citaSucursal != filterSucursal) {
                    return false;
                }
            }

            // filtro por canal
            const filterCanal = filtros.canal.toLowerCase();
            const citaCanal = cita.canal.toLowerCase();
            if(filterCanal != "0") {
                if(citaCanal != filterCanal) {
                    return false;
                }
            }

            // filtro por doctor
            const filterDoctor = filtros.doctor.toLocaleLowerCase();
            const citaDoctor = cita.doctor.toLocaleLowerCase()
            if(filterDoctor != "0") {
                if(citaDoctor != filterDoctor) {
                    return false;
                }
            }

            // barra de busqueda por telefono, nombre del cliente
            const telefono = cita.telefono;
            const nombreCliente = cita.cliente;
            const searchFilter = search.toUpperCase();
            if(searchFilter.length !== 0){
                if(!telefono.includes(searchFilter) 
                    && !nombreCliente.includes(searchFilter)
                ){
                    return false;
                }
            }

            return true
        });
}

export const filtrosCitas = (list: Array<CsvData>, filtros: FilterCitas, search: string) => {
    return list.filter(cita => {

            // filtro por sucursal
            const filterSucursal = filtros.sucursal.toLowerCase();
            const citaSucursal = cita.sucursal.toLowerCase();
            if(filterSucursal != "0") {
                if(citaSucursal != filterSucursal) {
                    return false;
                }
            }

            // filtro por doctor
            const filterDoctor = filtros.doctor.toLocaleLowerCase();
            const citaDoctor = cita.doctor.toLocaleLowerCase()
            if(filterDoctor != "0") {
                if(citaDoctor != filterDoctor) {
                    return false;
                }
            }

            // barra de busqueda por telefono, nombre del cliente
            const telefono = cita.telefono;
            const nombreCliente = cita.cliente;
            const searchFilter = search.toUpperCase();
            if(searchFilter.length !== 0){
                if(!telefono.includes(searchFilter) 
                    && !nombreCliente.includes(searchFilter)
                ){
                    return false;
                }
            }

            return true
        });
}