import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react"
import { FilterCitas, ShowFilters, UserResponse } from "../../types"
import { EstadoCitasList, SucursalesList } from "../../utilities/stateEnums"
import { UserServices, UtilsServices } from "../../services"
import { SucursalResponse } from "../../types/utils"

interface Props {
    filters: FilterCitas,
    setFilters: Dispatch<SetStateAction<FilterCitas>>
    showFilters: ShowFilters;
}

export function FiltrosMetricas({ filters, setFilters, showFilters }: Props) {

    const userService = new UserServices();
    const utilsService = new UtilsServices();

    const [doctorList, setDoctorList] = useState<Array<UserResponse>>([]);
    const [sucursales, setSucursales] = useState<SucursalResponse[]>([]);

    const onFilterChange = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        setFilters({
            ...filters,
            [event.target.name]: event.target.value
        });
    }

    useEffect(() => {
        if(showFilters.doctores) {
            userService.GetUserByRole("Doctor")
                .then(response => {
                    setDoctorList(response.data)
                })
                .catch(error => console.error(error));
        }

        if(showFilters.sucursales) {
            utilsService.GetSucursalList()
                .then(data => setSucursales(data));
            console.log("hola pirinola");
        }

    }, []);

    return (
        <div className="rounded-lg w-full p-2 shadow-sm">
            <form className="grid grid-cols-4 w-full sm:gap-y-1 gap-x-3 text-left px-2">

            {showFilters.fechas && (<>
            <div className="col-span-1 flex flex-col sm:flex-row m-1">
                <label className="w-full sm:w-1/3 font-semibold text-[13px] sm:text-base" htmlFor="fechaInicio">Fecha inicial:</label>
                <input 
                    //className="inputdate w-11/12 sm:w-2/3"
                    className="w-full md:w-[180px] justify-start text-center font-normal focus:bg-slate-200 hover:border-gray-900 focus:border-gray-900 border-2 rounded-lg border-gray-200"
                    onChange={onFilterChange}
                    value={filters.fechaInicial}
                    id="fechaInicio"
                    type="date"
                    name="fechaInicial"/>
            </div>

            <div className="col-span-1 flex flex-col sm:flex-row m-1">
                <label className="w-full sm:w-1/3 font-semibold text-[13px] sm:text-base" htmlFor="fechaInicio">Fecha Final:</label>
                <input 
                    className="w-full md:w-[180px] justify-start text-center font-normal focus:bg-slate-200 hover:border-gray-900 focus:border-gray-900 border-2 rounded-lg border-gray-200"
                    onChange={onFilterChange}
                    value={filters.fechaFinal}
                    id="fechaInicio"
                    type="date"
                    name="fechaFinal"/>
            </div>
            </>)}

            {showFilters.respuestas && (
            <div className="col-span-2 flex flex-col sm:flex-row m-1">
                <label className="w-full sm:w-1/3 font-semibold text-center text-[13px] sm:text-base">Respuesta:</label>
                <select 
                    className="w-full justify-start text-left font-normal focus:border-blue-950 border-2 rounded-lg border-gray-200 px-2"
                    onChange={onFilterChange}
                    value={filters.respuesta}
                    name="respuesta">
                    <option value={0} defaultChecked>Todas las respuestas</option>
                    {EstadoCitasList.map((respuesta, index) => (
                    <option key={index} value={respuesta}>{respuesta}</option>
                    ))}
                </select>
            </div>
            )}

            {showFilters.sucursales && (
            <div className="col-span-2 flex flex-col sm:flex-row m-1">
                <label className="w-full sm:w-1/3 font-semibold text-center text-[13px] sm:text-base">Sucursal:</label>
                <select 
                    className="w-full justify-start text-left font-normal focus:border-blue-950 border-2 rounded-lg border-gray-200 px-2"
                    onChange={onFilterChange}
                    value={filters.sucursal}
                    name="sucursal">
                    <option value={0} defaultChecked>Todas las sucursales</option>
                    {/* <option value={7} defaultChecked>Santa Catarina</option>
                    <option value={2} defaultChecked>San Nicolas</option> */}
                    {sucursales.map((sucursal, index) => (
                    <option key={index} value={sucursal.id}>{sucursal.nombre}</option>
                    ))}
                </select>
            </div>
            )}

            {showFilters.doctores && (
            <div className="col-span-2 flex flex-col sm:flex-row m-1">
                <label className="w-full sm:w-1/3 font-semibold text-center text-[13px] sm:text-base">Doctor:</label>
                <select 
                    className="w-full justify-start text-left font-normal focus:border-blue-950 border-2 rounded-lg border-gray-200 px-2"
                    onChange={onFilterChange}
                    name="doctor">
                    <option value={0} defaultChecked>Todos los doctores</option>
                    {doctorList.map((doctor, index) => (
                    <option key={index}>{doctor.nombre}</option>
                    ))}
                </select>
            </div>
            )}

            {showFilters.canales && (
            <div className="col-span-2 flex flex-col sm:flex-row m-1">
                <label className="w-full sm:w-1/3 font-semibold text-center text-[13px] sm:text-base">Canal:</label>
                <select 
                    className="w-full justify-start text-left font-normal focus:border-blue-950 border-2 rounded-lg border-gray-200 px-2"
                    onChange={onFilterChange}
                    value={filters.canal}
                    name="canal">
                    <option value={0} defaultChecked>Todos los canales</option>
                    <option value={"IVR"} defaultChecked>IVR</option>
                    <option value={"MENSAJE"} defaultChecked>Mensaje</option>
                </select>
            </div>
            )}

        </form>
        </div>
    )
}
