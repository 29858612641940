import { Input } from "@mui/joy";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

interface Props {
    searchValue: string
    setSearchValue: Dispatch<SetStateAction<string>>
}

export function SearchFilter({ searchValue, setSearchValue }: Props) {

    const onSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    }

    return (
        <div>
            <Input 
                placeholder="Bucar por télefono, nombre del cliente"
                value={searchValue}
                onChange={onSearchValueChange}
                className="w-full font-semibold focus:bg-slate-200 focus:border-gray-900 border-2 rounded-lg border-gray-300 my-2"
                type="text"
                size="sm"
                color="neutral"/>
        </div>
    );
}