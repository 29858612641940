import { Dispatch, SetStateAction, useState } from "react";

interface Props {
    nextPage: boolean;
    setPageNumber: Dispatch<SetStateAction<number>>;
    pageNumber: number;
}

export function PaginationButtons({ nextPage, setPageNumber, pageNumber }: Props) {

    
    const OnClickNextPage = () => {
        setPageNumber(pageNumber + 1);
    }

    const OnClickPreviousPage = () => {
        setPageNumber(pageNumber - 1);
    }

    return (
        <div className="flex gap-4 mb-10 h-[26px]">
            <div className="w-40 ">
                {pageNumber > 1 && (
                    <button 
                        className="w-40 border border-red-900 rounded-md"
                        onClick={OnClickPreviousPage}>
                        Página anterior {pageNumber - 1}
                    </button>
                )}
            </div>

            <p className="font-semibold">{pageNumber}</p>

            <div className="w-40 ">
                {nextPage && (
                    <button 
                        className="w-40 border border-red-900 rounded-md"
                        onClick={OnClickNextPage}>
                        Página siguiente {pageNumber + 1}
                    </button>
                )}
            </div>
        </div>
    );
}